import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Row, Col, Button, ButtonGroup, Badge, Spinner, Tooltip, OverlayTrigger, Modal, Container, Card } from 'react-bootstrap';


import { getSelectedElement, checkComponentPresence, compareName, getValue, propComparator } from "../../util/ui_utils.js"

import ModalUser from '../../components/ModalUser'


import ModalUserPreview from '../ModalUserPreview.js';
import { editUserData } from '../../Firebase2/Storage2/dbUsers.js';
import { disableUser, removeUserFromAllDomain } from '../FirebaseApi/user_api';
export const OneUser = ({ t, i, ui_list, c_list, app_roles, index, domain, user_list, setUserList, functions, not_contract }) => {
	console.log("USER LIST", user_list)
	const handleDelete = async (el) => {
		console.log(el)
		//		const ret_value = await removeUserFromDomain(el.id, domain)
		const ret_value = await editUserData(domain, el.id, { ...el, is_deleted: true })
		const a = await removeUserFromAllDomain(el.id)
		const b = await disableUser(el.id, true)
		console.log(ret_value)
		let editlist = [...user_list]
		const c = checkComponentPresence(el, user_list, 'id')
		editlist.splice(c, 1)
		setUserList(editlist)
		try {
			functions.edit({ name: 'user_list', value: editlist })
		} catch (err) {

		}
	}

	return (
		<Row className="border-bottom mb-1 pb-1" >
			<Col sm="2" >
				{i.debug_user && <>TEST DEBUG<FontAwesomeIcon icon="cogs" /></>}
				{i.disabled ? <FontAwesomeIcon icon="exclamation-triangle" /> : ''}
				<b>{i.displayName}</b> {i.username} <br />
				{i.email}</Col>
			<Col>
				{i.systempermission ?
					<Col>
						<Row>
							Permessi di visualizzazione di sistema personalizzati
						</Row>
					</Col>
					:
					<>
						{i.systemgroups && i.systemgroups.length > 0 ?
							<Card className="border-danger mb-3">
								<Card.Header className="p-1">
									Ruoli di sistema
								</Card.Header>
								<Card.Body className="pt-1 pb-1">
									<Row>
										<Col sm="2">
											{i.systemgroups.map((g, k) => (
												<Badge key={k} variant="info" className="me-1">{ui_list.group_dict[g]?.name}</Badge>
											))}
										</Col>
									</Row>
								</Card.Body>
							</Card>
							: ''}



						{i.contractgroups && i.contractgroups.length > 0 ?
							<Card className="border-warning mb-3">
								<Card.Header className="bg-warning p-1">
									{t('global.labels.contractpassing')}
								</Card.Header>
								<Card.Body className="pt-1 pb-1">
									<Row className="bg-secondary text-light">
										<Col >{t('global.labels.contract')} </Col>
										<Col >{t('global.labels.structure')}/{t('global.labels.department')} </Col>
										<Col>{t('global.labels.workarea')} </Col>
										<Col >{t('global.labels.role')} </Col>
									</Row>
									{i.contractgroups.map((g, k) => (
										<Row key={k} className="border-bottom">
											<Col>
												{getValue(getSelectedElement(g.contract_id, c_list, 'id'), 'name')}
											</Col>

											<Col>
												{ }
												{getValue(g.structure, 'name', t('structure.labels.all_structure'))} {g.department && g.department.id ? `/${getValue(g.department, 'name', t('structure.labels.all_department'))}` : ''}
											</Col>
											<Col>
												{getValue(g.workarea, 'name')}
											</Col>
											<Col>
												{g.permissiongroup.map((p, kk) => (
													<Badge key={k + kk} variant="primary" className="me-1">{ui_list.group_dict[p]?.name}</Badge>
												))}
											</Col>
										</Row>
									))}

								</Card.Body>
							</Card>
							: ''}

					</>}


				{i.appcontractgroups && i.appcontractgroups.length > 0 ?
					<Card className="border-primary mb-3" >
						<Card.Header className="p-1">
							{t('user.labels.appcontract_permissions')}
						</Card.Header>
						<Card.Body className="pt-1 pb-1">
							<Row className="bg-secondary text-light">
								<Col >{t('global.labels.contract')} </Col>
								<Col >{t('global.labels.structure')} </Col>
								<Col >{t('global.labels.role')} </Col>
							</Row>
							{i.appcontractgroups.map((g, k) => (
								<Row key={k} className="border-bottom">
									<Col>
										{getValue(getSelectedElement(g.contract_id, c_list, 'id'), 'name')}
									</Col>
									<Col>
										{getValue(g.structure, 'name')}
									</Col>
									<Col>
										{g.permissiongroup.map((p, kk) => (
											<Badge key={k + kk} variant="primary" className="me-1">{app_roles[p].name}</Badge>
										))}
									</Col>
								</Row>
							))}

						</Card.Body>
					</Card>
					: ''}





			</Col>
			<Col className="text-center" sm="2" md="1">
				{i.messagegroups && i.messagegroups.length > 0 ?
					<OverlayTrigger
						placement="auto"
						delay={{ show: 250, hide: 100 }}
						overlay={
							<Tooltip  >
								{i.messagegroups.length} {t('user.popover.has_messagegroup')}
							</Tooltip>}
					>
						<FontAwesomeIcon icon="comment" />
					</OverlayTrigger>
					: ''}
				{i.sendmessagegroups && i.sendmessagegroups.length > 0 ?
					<OverlayTrigger
						placement="auto"
						delay={{ show: 250, hide: 100 }}
						overlay={
							<Tooltip  >
								{i.sendmessagegroups.length} {t('user.popover.has_sendmessagegroup')}
							</Tooltip>}
					>
						<FontAwesomeIcon icon="comment" className="text-primary" />
					</OverlayTrigger>
					: ''}
				<ButtonGroup>
					<ModalUserPreview item={i} />
					<ModalUser t={t} item={i} item_list={user_list} ui_list={ui_list} domain={domain} setParentList={setUserList} index={index} contract_list={c_list} not_contract={not_contract} />
					<Button variant="danger" size="sm" onClick={() => handleDelete(i)}><FontAwesomeIcon icon='trash' /></Button>
				</ButtonGroup>
			</Col>
		</Row >

	)
}



